<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setPageMetaTags } from '@/helpers/page-meta'

export default {
  async created() {
    if (navigator.language) {
      this.$store.dispatch('newAppLanguage', { locale: navigator.language })
    }

    setPageMetaTags()
  }
}
</script>
