import { createRouter, createWebHistory } from 'vue-router'
import { setPageTitle } from '@/helpers/page-meta'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title)
  next()
})

export default router
