import { createStore } from 'vuex'
import { getAnalytics, logEvent } from 'firebase/analytics'
import Locales from '@/libraries/locales'
import ShortLocales from '@/libraries/short.locales'
import { setPageTitle, setPageMetaTags, setPageLang } from '@/helpers/page-meta'

export default createStore({
  state: {
    locale: 'en-US'
  },
  mutations: {
    setAppLanguage(state, locale) {
      state.locale = locale
    }
  },
  actions: {
    logEvent(context, { eventName = null, eventParams = null }) {
      if (!eventName) { return }

      try {
        if (eventParams) {
          logEvent(getAnalytics(), eventName, eventParams)
        } else {
          logEvent(getAnalytics(), eventName)
        }
      } catch (e) {
        //
      }
    },
    newAppLanguage({ commit, dispatch }, { locale, save }) {
      if (!locale) { return }

      const arr = locale.split('-')
      if (arr && arr.length) {
        if (arr.length === 2) {
          arr[0].toLowerCase()
          arr[1].toUpperCase()
          locale = arr.join('-')
        } else if (arr.length === 1) {
          arr[0].toLowerCase()
          if (ShortLocales[arr[0]]) {
            locale = ShortLocales[arr[0]]
          }
        }
      }

      if (!locale || !Locales[locale]) { locale = 'en-US' }

      commit('setAppLanguage', locale)

      setPageTitle()
      setPageMetaTags()
      setPageLang(locale)

      if (save) { dispatch('logEvent', { eventName: save, eventParams: locale }) }
    }
  },
  getters: {
    locale: s => s.locale,
    pages: () => {
      return {
        Transactions: {
          mainInfo: ['TransactionsInfo', 'MinusPlusTransactions'],
          sections: ['AddTransaction', 'AccountTransfer', 'CopyTransaction', 'EditTransaction', 'DeleteTransaction', 'SortingTransactions', 'FilteringTransactions', 'TransactionsSettings', 'DistributionsAmongAccounts', 'DataBarTransactions']
        },
        Accounts: {
          mainInfo: ['AccountsInfo'],
          sections: ['AddAccount', 'EditAccount', 'DeleteAccount', 'MainAccount', 'SortingAccounts', 'FilteringAccounts', 'ArchivedAccounts', 'Banks', 'Cards', 'CreditLimit', 'MoneyBoxes', 'DataBarAccounts']
        },
        Tags: {
          mainInfo: ['TagsInfo'],
          sections: ['AddTag', 'EditTag', 'DeleteTag', 'TagComment', 'TravelTags', 'FilteringTags', 'ArchivedTags', 'TagsCollections', 'TagStatistics', 'DataBarTags']
        },
        Settings: {
          mainInfo: ['SettingsInfo'],
          sections: ['DefaultCurrency', 'UserLimits', 'AppLanguage', 'DeleteUser']
        },
        App: {
          mainInfo: [],
          sections: ['IosDesktop']
        }
      }
    }
  }
})
