import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

import 'tailwindcss/tailwind.css'

import localizeFilter from '@/filters/localize.filter'

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true
}

const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENTID
})

getAnalytics(firebaseApp)

const app = createApp(App)
  .use(router)
  .use(store)

app.config.globalProperties.$filters = {
  localizeFilter
}

app.mount('#app')
logEvent(getAnalytics(), 'appCreatedHELP')
