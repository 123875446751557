import store from '../store'

const locales = {
  'ru-RU': require('../locales/ru-RU.json'),
  'en-US': require('../locales/en-US.json')
}

export default function localizeFilter(key) {
  const locale = store.getters.locale || 'en-US'

  let localeKey = locales[locale][key]

  if (!localeKey) {
    localeKey = locales['en-US'][key]

    if (!localeKey) { return 'NO-KEY-FOUND' }
  }

  return localeKey
}
